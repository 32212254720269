.notification-box {
  border-radius: 8px;
  margin: 0 0 8px;
  overflow: visible;
  position: relative !important;
  background-color: #fff !important;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08);
  transition: box-shadow 83ms;
  margin-bottom: 25px;
  padding: 5px 0px;
  display: flex;
  justify-content: space-between;
}

.notification-box:hover {
  cursor: pointer;
}

.unread-notification {
  background-color: #e2f0fe !important;
}
