.post-button {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  width: 100% !important;
  background-color: #f9ae00 !important;
  font-weight: 500 !important;
  height: 50px;
}

.post-button:hover,
.post-button:focus,
.save-post-button:hover,
.save-post-button:focus {
  background-color: #f9ae00 !important;
  color: black !important;
  border-color: white !important;
}

.post-image-button {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-top: 10px;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.reduced-divider-margin {
  margin: 15px 0 0px 0 !important;
}

.create-post-modal {
  .ant-modal-content {
    border-radius: 12px;
  }
  .ant-modal-header {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-input:hover,
  .ant-input-number:hover,
  .ant-input:focus,
  .ant-input-number:focus,
  .ant-input-number:active {
    border-color: #f9ae00;
    box-shadow: 0 0 0 0.5px rgb(249, 174, 0 / 20%);
  }
}

.create-comment-modal {
  .ant-input:hover,
  .ant-input-number:hover,
  .ant-input:focus,
  .ant-input-number:focus,
  .ant-input-number:active {
    border-color: #f9ae00;
    box-shadow: 0 0 0 0.5px rgb(249, 174, 0 / 20%);
  }
}

.ant-modal-close-x:focus-visible {
  outline: 0 !important;
  outline: none !important;
}

.taglist {
  display: flex;
  flex-wrap: wrap;
}

.tag-item,
.tag-item-post {
  margin-bottom: 5px !important;
}

.tag-item:hover {
  cursor: pointer;
}

.save-post-button {
  background-color: #f9ae00 !important;
  font-weight: 500 !important;
}

.radio-btn-filter {
  flex: 1 !important;
  justify-content: center !important;
  display: flex !important;
  font-weight: 500 !important;
  color: black !important;
}

.radio-grp-custom {
  display: flex !important;
  width: 100% !important;
  margin-top: 15px;
}

.radio-btn-filter:hover,
.radio-btn-filter:focus {
  border-color: #f9ae00 !important;
  box-shadow: 0 0 0 0.5px rgb(249, 174, 0 / 20%) !important;
}

.radio-btn-filter.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: #f9ae00 !important;
}

.radio-btn-filter.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #f9ae00 !important;
}

.radio-grp-custom {
  .ant-radio-button-wrapper-checked {
    background-color: #f9ae00 !important;
  }
}

.post-box {
  border-radius: 8px;
  margin: 0 0 8px;
  overflow: visible;
  position: relative !important;
  background-color: #fff !important;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08);
  transition: box-shadow 83ms;
  margin-bottom: 30px;
}

.editorClassName {
  border: 1px solid #d9d9d9;
  margin-bottom: 15px;
}

.more-item-icon {
  font-size: 25px;
  font-weight: bolder;
}

.more-item-icon:hover {
  cursor: pointer;
}

.text-bold {
  font-weight: bold;
}

.action-buttons-box {
  display: flex;
  justify-content: space-between;
}

.posts {
  /* Position and sizing of burger button */
  .bm-burger-button {
    display: none;
  }
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #f9ae00;
  }
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  /* Color/shape of close button cross */
  .bm-cross {
    background: #f9ae00;
  }
  #react-burger-menu-btn:focus {
    outline: 0;
  }
  /*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  /* General sidebar styles */
  .bm-menu {
    background: black;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: black;
  }
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
}

.posts-heading {
  width: 100%;
  margin: 0 auto;
  font-weight: bold;
  font-size: 20px;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
}

.menu-button {
  z-index: 1000;
}

.menu-btn-heading-parent {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.favorate-container {
  margin-right: 10px;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.like-container {
  margin-right: 5px;
}

.like-icon {
  font-size: "20px";
  color: red !important;
}

.comment-icon {
  font-size: "20px" !important;
}

.like-count-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 10px;
  border-radius: 5px;
}

.like-count-container:hover {
  background: lightgray;
  cursor: pointer;
}

.sticky-box {
  position: fixed;
}

.write-post-icon {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 15px;
  right: 25px;
  z-index: 9999;
  align-items: center;
  align-self: flex-end;
  background-color: #f9ae00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 12px;
  cursor: pointer;
  display: flex;
  font-size: 2em;
  height: 64px;
  justify-content: center;
  margin-bottom: 20px;
  margin-right: 20px;
  width: 64px;
}

.see-replies-box {
  margin-bottom: 15px;
  margin-top: -5px;
  margin-left: 60px;
}

.notification-count {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fa3e3e;
  border-radius: 15px;
  width: 17px;
  position: absolute;
  font-size: 9px;
  height: 17px;
  position: absolute;
  top: -5px;
  left: 6px;
  color: black !important;
}

.bottom-tab-notification-count {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fa3e3e;
  border-radius: 15px;
  width: 21px;
  position: absolute;
  font-size: 9px;
  height: 21px;
  position: absolute;
  top: -3px;
  right: 30px;
  color: white !important;
  font-weight: 500;
}

.rules-title {
  font-weight: bold;
}

@media only screen and (max-width: 767px) {
  .write-post-icon {
    bottom: 60px;
    right: 0px;
  }
}
