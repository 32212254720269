@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.cdnfonts.com/css/poppins");

.margin-app {
  min-height: 500px;
}

body {
  font-size: 14px;
  // font-family: Poppins !important;
}

body::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: black; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #f9ae00; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 1px solid black; /* creates padding around scroll thumb */
}

.dot.selected {
  background: #f9ae00 !important;
  box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.7) !important;
}

.dot.selected:focus {
  outline: 0 !important;
}

.dot {
  background: #979197 !important;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 30px;
  height: 25px;
  right: 25px;
  top: 24px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #f9ae00;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #f9ae00;
}

// New box css

/* Variables */
$hoverBgColor : #F9AE00;
$hoverBgColor2: #e7be5f;

/* Mixins */
@mixin createBox($width, $height, $bRadius, $bgColor, $margin){
  width: $width;
  height: $height;
  border-radius: $bRadius;
  background-color: $bgColor;
  margin: $margin;
  transition: .3s all ease-in-out;
}

.new-box{
  .box 
  {
    @include createBox(180px, 180px, 10px, white, 20px);
    position: relative;
    box-shadow: 0px 0px 15px #F9AE00;
    cursor: pointer;
    .boxContent{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 15px;
      height: 100%;
      .title{
        font-size: 18px;
        color: black;
        font-weight: bold;
        padding: 10px;
      }
    }
    &:hover{
      background: linear-gradient(130deg, $hoverBgColor 0%, $hoverBgColor2 100%);
      box-shadow: none;
      svg,
      .title{
        color: white;
        transition: .2s all ease-in-out;
      }
    }
  }
}


#react-burger-menu-btn:focus {
  outline: 0;
}
/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: black;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: black;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0 !important;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.counter-background {
  background: linear-gradient(180deg, #fff0ad 0%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(180deg, #f9ae00 0%, rgba(255, 255, 255, 0) 68.75%),
    linear-gradient(0deg, #f9ae00, #f9ae00);
}

.sticky-header-enabled .margin-app {
  margin-top: 75px;
}

.navbar {
  background-color: white !important;
  z-index: 9;
}

.sticky-nav {
  position: sticky;
  top: 0;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  transition: all 0.5s ease;
  animation: smoothScroll 1s forwards;
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-142px);
  }

  100% {
    transform: translateY(0px);
  }
}

.grey-bg {
  background-color: #ededed;
}

.black-bg {
  background-color: black;
}

.white-bg {
  background-color: white;
}

.padding-20 {
  padding: 20px 0;
}

.padding-30 {
  padding: 30px 0;
}

.padding-20-all {
  padding: 20px !important;
}

.testimonial-slider .carousel .slide {
  background: transparent !important;
  color: black;
  height: 100%;
}

.blogs-slider-mobile .carousel .slide {
  background: transparent !important;
  color: black;
  height: 100%;
}

.testimonial-slider .myCarousel {
  background: #fafafa;
  margin-top: -6%;
  width: 54%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #ddd;
  height: 286px;
}

.testimonial-slider .carousel .control-dots {
  padding-left: 5px !important;
  outline: 0;
  bottom: 0% !important;
}

.testimonial-slider-content .carousel-root {
  margin-top: 0% !important;
}

.myCarousel h3 {
  color: #222;
  font-weight: 100;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
}

.myCarousel h4 {
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  font-weight: 500;
  color: #787878;
  font-size: 14px;
}

.myCarousel p {
  font-weight: 100 !important;
  line-height: 29px !important;
  color: #222;
  font-size: 15px;
  font-family: sans-serif;
  max-height: 67px;
}

.myCarousel p:before {
  content: "“";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
}

.myCarousel p:after {
  content: "”";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
  line-height: 0;
}

.scrollTop {
  position: fixed;
  bottom: 40px;
  width: 80px;
  right: 100px;
  height: 50px;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
}

.scrollTop:hover {
  opacity: 1;
  color: #48c9b0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

.glow-on-hover {
  position: fixed;
  right: 116px;
  bottom: 35px;
  width: 50px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background: transparent;
  cursor: pointer;
  z-index: 0;
  border-radius: 27px;
}

.glow-on-hover:before {
  content: "";
  background: linear-gradient(45deg, #ff0000, #ff0000);
  position: absolute;
  top: 0px;
  left: 0px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 40px;
}

.glow-on-hover:active {
  color: #000;
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  left: -2px;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.custom-field-focus:focus {
  border-color: #f9ae00;
  box-shadow: 0 0 0 0.2rem rgba(249, 174, 0, 0.2);
}

.signUpBtn,
.get-started-btn {
  background-color: #f9ae00 !important;
  border-color: #f9ae00 !important;
  font-weight: 500 !important;
  color: black !important;
  span {
    font-weight: 500 !important;
    color: black !important;
  }
}

.signUpBtn:disabled {
  background: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  color: black !important;
  span {
    font-weight: 500 !important;
    color: black !important;
  }
}

.signUpBtn:hover,
.signUpBtn:focus,
.get-started-btn:hover,
.get-started-btn:focus {
  color: black !important;
  border-color: #f9ae00 !important;
}

.subscribe-btn {
  background-color: red !important;
  border-color: red !important;
  font-weight: 500 !important;
  color: #f9ae00 !important;
  height: 38px !important;
  border-radius: 4px;
  box-shadow: 0px 4.42006px 3.86756px rgba(0, 0, 0, 0.35);
  cursor: pointer;
  span {
    font-weight: 600 !important;
    color: white !important;
  }
  &:hover, &:active, &:focus {
    box-shadow: 0px 6.42006px 5.86756px rgba(0, 0, 0, 0.35) !important;
  }
}

.subscribe-section {
  border-radius: 15px;
}

.required::after {
  content: "*";
  color: red;
}

.go-back-icon {
  font-size: 18px;
  font-weight: 300;
}

.get-started-btn {
  border-color: none !important;
  width: 150px;
  border-radius: 6px !important;
}

.apply-button {
  width: initial !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.brand-image {
  height: 50px;
  width: 185px;
}

.brand-image:hover {
  cursor: pointer;
}

.brand-image-mobile {
  position: absolute;
  left: -5px;
  top: 7px;
  width: 70px;
  height: 50px;
}

.nav-list-custom {
  display: flex;
  justify-content: center;
  align-items: center;
}

.initials {
  height: 60px;
  width: 60px;
  background: #f9ae00;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.initials span {
  font-size: 45px;
  font-weight: 500;
  line-height: 50px;
  vertical-align: middle;
  margin-bottom: 10px;
}

.initials:hover,
.profile-image:hover {
  cursor: pointer;
}

.menu-key-list {
  padding: 0 !important;
}

.menu-key:first-child:hover {
  background-color: white !important;
  cursor: default;
}

.menu-key {
  width: 140px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-key:hover {
  background-color: #f9ae00 !important;
}

.ant-btn-loading-icon {
  line-height: 10px !important;
  vertical-align: middle !important;
  margin-bottom: 4px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.no-bullet {
  padding-left: 0;
}

.no-bullet li {
  list-style-type: none;
  margin-bottom: 10px;
}

.blog-detail-container {
  margin-top: 50px;
  margin-bottom: 100px;
  h4,
  h3,
  h5 {
    margin: 15px 0;
  }
}

.react-share__ShareButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.react-share__ShareButton:focus {
  outline: 0;
}

.text-center {
  text-align: center !important;
}

.position-relative {
  position: relative;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-20 {
  margin-bottom: 20px;
}

.overlay-image {
  height: 100%;
  width: 80%;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  flex-direction: column;
}

.join-us-button {
  color: white !important;
  background-color: darkorchid !important;
  border: 1px solid darkorchid !important;
  outline: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
  margin-top: 10px;
  height: 40px !important;
  font-size: 15px !important;
  border-radius: 6px !important;
  font-weight: bold !important;
}

.overlay-image:hover {
  cursor: pointer;
  .join-us-button {
    visibility: visible;
    opacity: 1;
  }
  .join-us-button:hover {
    outline: 0;
    border: 1px solid darkorchid !important;
  }
}

.blog-parent-div .ant-card-bordered {
  border: 2px solid #f0f0f0;
}

.leadform .form-label {
  margin-bottom: 0;
}

.leadform .form-group {
  margin-bottom: 5px;
}

.leadform .custom-field-focus {
  height: 35px;
}

.plan-card {
  display: flex !important;
  justify-content: center;
  margin-bottom: 30px;
}

.blogs-brief .ant-card-hoverable:hover {
  background: black;
  border: 2px solid #f9ae00;
  .ant-card-body {
    background: black;
  }
  .ant-card-meta-title {
    color: #f9ae00;
  }
  .ant-card-meta-description {
    color: #f9ae00;
  }
}

.blogs-brief-bg {
  background: #ededed;
  padding: 30px 0px;
}

.testimonials-brief-bg {
  padding: 30px 0px;
}

.site-collapse-custom-collapse {
  background: white !important;
}

.site-collapse-custom-collapse-gray {
  background: #f7f7f7 !important;
}

.testimonial-slider .carousel.carousel-slider .control-arrow:hover {
  background-color: transparent !important;
}

.site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse-gray .site-collapse-custom-panel {
  background: #f7f7f7 !important;
  border-radius: 2px !important;
  margin-bottom: 24px !important;
  border: 0px !important;
  overflow: hidden !important;
}

.site-collapse-custom-collapse .site-collapse-custom-panel-watchlist,
.site-collapse-custom-collapse-gray .site-collapse-custom-panel-watchlist {
  background: #f7f7f7 !important;
  border-radius: 2px !important;
  border: 0px !important;
  overflow: hidden !important;
}

.site-collapse-custom-panel .ant-collapse-header,
.site-collapse-custom-panel-watchlist .ant-collapse-header {
  font-weight: 500 !important;
}

.no-margin-bottom {
  margin-bottom: 0px !important;
}

.ant-card-meta-title {
  height: 75px !important;
  white-space: inherit !important;
}

.ant-card-meta-description p:first-child {
  height: 44px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.subscribe-heading {
  margin-top: 10px;
  font-family: Poppins;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: center;
}

.site-links-list {
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  width: 300px;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  li {
    float: left;
    color: #f9ae00;
    list-style-type: none;
    padding: 0px 10px;
    font-weight: 500;
  }
  li:not(:last-child) {
    border-right: 1px solid #f9ae00;
  }
  li:hover {
    cursor: pointer;
  }
}

.reduced-divider-margin-10 {
  margin: 10px 0 !important;
}

.exit-intent-popup {
  position: relative;
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-body {
    // background: linear-gradient(180deg, #fff0ad, hsla(0, 0%, 100%, 0)),
    //   linear-gradient(180deg, #f9ae00, hsla(0, 0%, 100%, 0) 68.75%),
    //   linear-gradient(0deg, #f9ae00, #f9ae00);
    background-color: white;
    border-radius: 15px;
    border: 5px solid #f9ae00;
  }
  .ant-modal-close-x {
    width: 35px;
    height: 35px;
    line-height: 30px;
    display: none;
  }
  .ant-modal-close-x:focus,
  .ant-modal-close:focus {
    outline: 0 !important;
  }
  .ant-modal-content {
    border-radius: 15px;
  }

  .leaving-already {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
  }

  .close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 9;
    font-size: 25px;
  }
  .close-icon:hover {
    cursor: pointer;
  }
}

.contact-us-text {
  text-align: center;
  width: 100px;
  position: fixed;
  bottom: 100px;
  right: 30px;
  font-weight: 500;
  z-index: 9999;
}

.contact-us-text-landing {
  text-align: center;
  width: 100px;
  position: fixed;
  bottom: 95px;
  right: 45px;
  font-weight: 500;
  z-index: 9999;
}

.mt-5px {
  margin-top: 5px;
}

.mr-5px {
  margin-right: 5px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.overlay-block {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.5s ease;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
}

.whatsapp-button {
  div {
    div:nth-child(2) {
      background-color: #25d366 !important;
    }
  }
  svg {
    color: white !important;
  }
}

.whatsapp-button-landing {
  div:first-child {
    bottom: 3px !important;
    right: 40px !important;
    div:nth-child(2) {
      width: 60px;
      height: 60px;
    }
  }
}

.telegram-popup {
  position: relative;
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-body {
    background: white;
    border-radius: 15px;
  }
  .ant-modal-close-x {
    width: 35px;
    height: 35px;
    line-height: 30px;
    display: none;
  }
  .ant-modal-close-x:focus,
  .ant-modal-close:focus {
    outline: 0 !important;
  }
  .ant-modal-content {
    border-radius: 15px;
  }

  .leaving-already {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
  }

  .close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 9;
    font-size: 25px;
  }
  .close-icon:hover {
    cursor: pointer;
  }
}

.fb-chat-support {
  iframe {
    right: 110px !important;
    bottom: 36px !important;
  }
}

.margin-40-top {
  margin-top: 40px;
}

.margin-40-bottom {
  margin-bottom: 40px;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.img-container-1 {
  padding-top: 100%;
  height: 0;
  width: 100%;
  display: block;
  position: relative;
  background-color: #fff;
}

.img-cont-2 {
  width: 100%;
  height: 100%;
  display: flex !important;
}

.ant-spin-text {
  color: #f9ae00 !important;
  font-size: 20px;
  font-weight: 600;
}

.ant-spin-dot-item {
  background-color: #f9ae00 !important;
}

.comments-gray {
  font-size: 12px;
  color: darkgray;
}

.comment-button {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding-left: 5px !important;
}

@media only screen and (max-width: 934px) {
  .myCarousel {
    background: #fafafa;
    margin-top: -9%;
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    padding-bottom: 12.5%;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid #ddd;
    height: 269px;
  }
}

.pulse:hover,
.pulse:focus {
  animation: pulse 1s;
  box-shadow: 0 0 0 2em rgba(#fff, 0);
}

$colors: (
  pulse: rgb(0, 200, 5),
);

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--hover);
  }
}

@each $button, $color in $colors {
  .#{$button} {
    --color: #{$color};
    --hover: darkorchid;
  }
}

button {
  color: var(--color);
  transition: 0.25s;

  &:hover,
  &:focus {
    border-color: var(--hover);
    color: #fff;
  }
}

.pulse-telegram:hover,
.pulse-telegram:focus {
  animation: pulse 1s;
  box-shadow: 0 0 0 2em rgba(#fff, 0);
}

$colorsTel: (
  pulse-telegram: rgb(0, 200, 5),
);

@keyframes pulse-telegram {
  0% {
    box-shadow: 0 0 0 0 var(--hover);
  }
}

@each $button, $color in $colorsTel {
  .#{$button} {
    --color: #{$color};
    --hover: #0088cc;
  }
}

button {
  color: var(--color);
  transition: 0.25s;

  &:hover,
  &:focus {
    border-color: var(--hover);
    color: #fff;
  }
}

.pulse-main:hover,
.pulse-main:focus {
  animation: pulse 1s;
  box-shadow: 0 0 0 2em rgba(#fff, 0);
}

$colorsTel: (
  pulse-main: rgb(0, 200, 5),
);

@keyframes pulse-main {
  0% {
    box-shadow: 0 0 0 0 var(--hover);
  }
}

@each $button, $color in $colorsTel {
  .#{$button} {
    --color: #{$color};
    --hover: #f9ae00;
  }
}

.show-margin {
  margin-right: 30px !important;
}

.home-slider .carousel-status {
  font-size: 20px !important;
}

.get-started-btn svg {
  margin-top: -6px !important;
}

.leadform .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f9ae00 !important;
  border-color: #f9ae00 !important;
}

.leadform .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #f9ae00 !important;
}

.how-it-works {
  width: 80% !important;
  margin-left: 50px !important;
}

.better-for-you {
  width: 80% !important;
}

.testimonials-parent .carousel .control-next.control-arrow:before {
  border-left: 15px solid #f9ae00;
}

.testimonials-parent .carousel .control-prev.control-arrow:before {
  border-right: 15px solid #f9ae00;
}

.testimonials-parent .carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: initial !important;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.slider-box {
  box-shadow: 1px 1px 7px -2px #000000;
  border-radius: 6px;
  margin-top: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: white;
}

.react-video-player-custom {
  iframe {
    border-radius: 20px;
  }
}

.play-btn {
  background: #f9ae00 !important;
  border-radius: 6px !important;
  height: 40px !important;
  font-size: 16px !important;
  font-weight: bold !important;
  margin-top: 15px;
  &:hover,
  &:focus,
  &:active {
    color: black !important;
    border-color: #f9ae00 !important;
  }
}

.not-showing {
  visibility: hidden;
  opacity: 0;
  transition: visibility 1s, opacity 0.5s linear;
}
.showing {
  visibility: visible;
  transition: visibility 1s, opacity 0.5s linear;
  opacity: 1;
}

#notfound {
  position: relative;
  height: 100vh;
  background: #030005;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 767px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
}

.notfound .notfound-404 {
  position: relative;
  height: 180px;
  margin-bottom: 20px;
  z-index: -1;
}

.notfound .notfound-404 h1 {
  font-family: "Montserrat", sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 224px;
  font-weight: 900;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: -12px;
  color: #030005;
  text-transform: uppercase;
  text-shadow: -1px -1px 0px darkorchid, 1px 1px 0px #f9ae00;
  letter-spacing: -20px;
}

.notfound .notfound-404 h2 {
  font-family: "Montserrat", sans-serif;
  position: absolute;
  left: 0;
  right: 0;
  top: 110px;
  font-size: 42px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0px 2px 0px darkorchid;
  letter-spacing: 13px;
  margin: 0;
}

.notfound .notfound-404 div:first-child {
  div {
    width: 250px;
    margin: 0 auto;
  }
  position: absolute;
  left: 0;
  right: 0;
  top: 250px;
  margin: 0;
}

.share-icon-class {
  display: block;
  width: 16px;
  height: 16px;
  backface-visibility: hidden;
  transition: all 0.2s ease;
}

.custom-upload-box {
  .ant-upload-list-picture-card {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-upload-select-picture-card,
  .ant-upload-list-picture-card-container,
  .ant-upload-list-item-list-type-picture-card {
    width: 150px !important;
    height: 150px !important;
  }
}

.export-users {
  position: absolute !important;
  right: 20px;
}

.marginl-15 {
  margin-left: 15px;
}

.marginl-10 {
  margin-left: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.nav-item-bottom-tab {
  position: relative;
}

.max-height-330 {
  height: 360px !important;
}

.watchlist-tab {
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-tab:hover,
  .ant-tabs-tab:active,
  .ant-tabs-tab:focus {
    color: #f9ae00 !important;
    font-weight: bold !important;
  }
  .ant-tabs-tab-btn {
    font-weight: bold !important;
  }
}

.blog-img-class {
  margin: 10px 0px;
  max-width: 100%;
  object-fit: contain;
  height: auto;
}

.blog-underline {
  border-bottom: 10px solid black;
}

@media only screen and (max-width: 767px) {
  .navbar.navbar.navbar-expand-sm.navbar-light.bg-light {
    height: 62px !important;
  }

  .play-btn {
    margin-bottom: 15px;
  }

  .brand-image {
    margin: 0;
  }

  .plan-card:last-child {
    margin-bottom: 30px;
  }

  .plan-card {
    margin-bottom: 80px;
  }

  .subscribe-section {
    margin: 0px 15px;
  }

  .notfound .notfound-404 h2 {
    font-size: 24px;
  }

  .blog-img-div {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .plan-card:last-child {
    margin-top: 30px;
  }

  .play-btn {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) {
  .home-slider .control-arrow.control-next,
  .home-slider .control-arrow.control-prev {
    width: 150px !important;
  }

  .home-slider .carousel .control-next.control-arrow:before {
    border-left: 35px solid #fff !important;
  }

  .home-slider .carousel .control-prev.control-arrow:before {
    border-right: 35px solid #fff !important;
  }

  .home-slider .carousel .control-arrow:before,
  .carousel.carousel-slider .control-arrow:before {
    border-top: 35px solid transparent !important;
    border-bottom: 35px solid transparent !important;
  }

  .testimonial-slider .carousel-root {
    width: 80% !important;
    margin: auto !important;
  }
}

@media only screen and (min-width: 991px) {
  .testimonial-slider .carousel-root {
    width: 90% !important;
    margin: auto !important;
  }
}

@media only screen and (max-width: 767px) {
  .home-slider .control-arrow.control-next,
  .home-slider .control-arrow.control-prev {
    display: none;
  }

  .bm-burger-button {
    right: 15px;
    top: 15px;
  }

  .contact-us-text {
    right: 5px;
  }

  .slider-box {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .whatsapp-button-landing {
    div:first-child {
      bottom: 3px !important;
      right: 20px !important;
      left: initial;
      transform: initial;
      width: initial;
    }
  }
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 h1 {
    font-size: 182px;
  }
}
