.bottom-tab-navigator {
  div {
    div {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    div:not(:last-child) {
      //   border-right: 1px solid black;
    }
  }
}

.bottom-tab-icon {
  font-size: 20px !important;
  color: #f9ae00 !important;
}

.text-black {
  color: black !important;
  font-weight: bold;
  font-size: 10px;
}
